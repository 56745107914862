<template>
  <div>
    <h4 class="orange">New Invoice</h4>
    <!-- <pre>{{ formData }}</pre> -->
    <!-- <pre>{{ user }}</pre> -->
    <form class="ms-5 me-5" @submit.prevent="submitInvoice">
      <fieldset ref="mainForm">
        <div class="row mb-3">
          <div class="col">
            <label class="form-label">Invoice Number</label>
            <input
              type="text"
              class="form-control"
              v-model="formData.invoiceNumber"
              required
            />
            <div class="row">
              <div class="col">
                <div class="form-text">Numero de facture</div>
              </div>
              <div class="col text-end">
                <div class="form-text">رقم الفاتورة</div>
              </div>
            </div>
          </div>
          <div class="col">
            <label class="form-label">Invoice Date</label>
            <input
              type="date"
              class="form-control"
              v-model="formData.invoiceDate"
              required
            />
            <div class="row">
              <div class="col">
                <div class="form-text">Date de la facture</div>
              </div>
              <div class="col text-end">
                <div class="form-text">تاريخ الفاتورة</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label class="form-label">Invoice Amount</label>
            <input
              type="number"
              step="0.01"
              class="form-control"
              v-model="formData.invoiceAmount"
              required
            />
            <div class="row">
              <div class="col">
                <div class="form-text">Montant de la facture</div>
              </div>
              <div class="col text-end">
                <div class="form-text">المبلغ</div>
              </div>
            </div>
          </div>
          <div class="col">
            <label class="form-label">Invoice Currency</label>
            <select class="form-select" v-model="formData.invoiceCurrency" required>
              <option value="">Select ...</option>
              <option v-for="currency in Currencies" :key="currency" :value="currency">
                {{ currency }}
              </option>
            </select>
            <div class="row">
              <div class="col">
                <div class="form-text">Devise de la facture</div>
              </div>
              <div class="col text-end">
                <div class="form-text">العملة</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label class="form-label">Reference Type</label>
            <select class="form-select" v-model="formData.referenceType" required>
              <option value="">Select ...</option>
              <option value="PO">PO</option>
              <option value="Contract">Contract</option>
            </select>
            <div class="row">
              <div class="col">
                <div class="form-text">Type de reference</div>
              </div>
              <div class="col text-end">
                <div class="form-text">نوع المرجع</div>
              </div>
            </div>
          </div>
          <div class="col">
            <label class="form-label">Reference Number</label>
            <input
              type="text"
              class="form-control"
              v-model="formData.referenceNumber"
              required
            />
            <div class="row">
              <div class="col">
                <div class="form-text">Numero de reference</div>
              </div>
              <div class="col text-end">
                <div class="form-text">رقم المرجع</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label class="form-label">Entity Name</label>
            <select class="form-select" v-model="formData.entityName" required>
              <option value="">Select ...</option>
              <option v-for="entity in Entities" :key="entity" :value="entity">
                {{ entity }}
              </option>
            </select>
            <div class="row">
              <div class="col">
                <div class="form-text">Nom de l'entite</div>
              </div>
              <div class="col text-end">
                <div class="form-text">اسم الشركة</div>
              </div>
            </div>
          </div>
          <div class="col">
            <label class="form-label">Attachments</label>
            <input class="form-control" type="file" ref="attachments" multiple required />
            <div class="row">
              <div class="col">
                <div class="form-text">Date de la facture</div>
              </div>
              <div class="col text-end">
                <div class="form-text">تاريخ الفاتورة</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3" v-if="fileList.length != 0">
          <div class="col">
            <label class="form-label">Attachments</label>
            <div v-for="file in fileList" :key="file.size" class="mb-2">
              <!-- <pre>{{ JSON.stringify(file) }}</pre> -->
              <p>
                {{ file.name }},
                <small>{{ Number(file.size / 1024).toFixed(2) }} kb, </small>
              </p>
              <div class="progress">
                <div
                  class="progress-bar bg-warning"
                  role="progressbar"
                  :style="{ width: `${file.UploadPercentage}%` }"
                  :aria-valuenow="file.UploadPercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-3 offset-9 d-grid">
            <button class="btn btn-md btn-outline-primary" type="submit">Submit</button>
          </div>
        </div>
        <!-- <div class="row" v-if="mainForm && !mainForm.disabled">
                    <div class="col">
                        <small>
                            <i>
                                You hereby confirm that by submission of your invoices through this online portal that all information provided on the submitted
                                invoices are both true and correct and that the amounts stated therein are indeed due and payable to you. You furthermore
                                confirm that there exists no disputes between yourself and the relevant OiLSERV subsidiary or affiliate company with regards to
                                the invoices. You furthermore indemnify and hold harmless OiLSERV against any damages that it may suffer in making payments to
                                you which are disputed or which are not due and payable to you and you agree to immediately reimburse OiLSERV any such payments
                                which are made to you but which were not legally due and payable to you. OiLSERV is furthermore not responsible for any errors
                                or omissions, or for the results obtained by you from the use of this online portal. OiLSERV cannot guarantee the absolute
                                security of information submitted by you and your use of this online portal and you do so at your own risk and absolve in full
                                OiLSERV against any damages or losses which you may incur. All information on this online portal is provided "as is", with no
                                guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this online portal. You agree to make
                                use of this online portal solely for the purpose for which it was provided to you and you agree that any unlawful use thereof
                                may result in civil or criminal legal action taken against you.
                            </i>
                        </small>
                    </div>
                </div> -->
      </fieldset>
    </form>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { AllCurrencies, AllEntities } from "@/config.js";
import {
  getStorage,
  ref as sRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getFirestore, collection, doc, addDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  name: "myInvoices",
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = computed(() => store.getters.getCurrentUser);
    const vendor = computed(() => store.getters.getCurrentUserVendor);
    const geoInfo = computed(() => store.getters.getCurrentUserGeoInfo);
    const Currencies = ref(AllCurrencies);
    const Entities = ref(AllEntities);
    const fileList = ref([]);
    const attachments = ref(null);
    const mainForm = ref(null);
    const files = ref([]);
    const formData = ref({
      invoiceStatus: "Submitted",
      invoiceNumber: "",
      invoiceAmount: null,
      invoiceCurrency: "USD",
      invoiceDate: null,
      referenceType: "",
      referenceNumber: null,
      entityName: "",
      uid: user.value.uid,
      ip: geoInfo.value,
      addedOn: moment().local().format(),
      attachments: [],
    });

    const submitInvoice = async function () {
      mainForm.value.disabled = true;
      fileList.value = [];
      files.value = attachments.value.files;
      files.value.forEach((file) => {
        let obj = {
          size: file.size,
          name: file.name,
          UploadPercentage: 0,
        };
        fileList.value.push(obj);
      });

      uploadAttachment();
    };

    const uploadAttachment = async function () {
      let alert = Swal.fire({
        title: "Submitting Invoice",
        html: "Please do not close this page",
        allowOutsideClick: false,
        allowEscapeKey: false,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        // Save Attachments and get URL
        const actions = fileList.value.map(saveAttachmentOnFirebase);
        Promise.all(actions).then(function (res) {
          res.forEach((attach) => {
            formData.value.attachments.push(attach);
          });
          setTimeout(async () => {
            let inv = JSON.parse(JSON.stringify(formData.value));
            inv.invoiceDate = moment(inv.invoiceDate).local().format();

            // Save Invoice on Firebase
            const uid = await saveInvoiceOnFirebase(inv);
            // Save Invoice on Sharepoint
            const spInvoice = await saveInvoiceOnSharepoint(formData.value, uid);
            console.log(spInvoice);
            // Update Invoice Status on Firebase
            const finalResults = await updateFirebaseInvoiceStatus(uid);
            console.log(finalResults);
            // All Tasks completed
            alert.close();
            Swal.fire("Submitted", "Invoice is submitted successfully", "success").then(
              () => {
                router.push({ name: "MyInvoices" });
              }
            );
          }, 500);
        });
      } catch (error) {
        alert.close();
        throw Error(error);
      }
    };

    const saveInvoiceOnFirebase = async function (invoice) {
      const db = getFirestore();
      const supplierCollectionRef = collection(db, "Suppliers");
      const supplierRef = doc(supplierCollectionRef, vendor.value.vendorID); //vendor.value.vendorID
      const invoiceRef = collection(supplierRef, "Invoices");
      try {
        const docRef = await addDoc(invoiceRef, invoice);
        return docRef.id;
      } catch (error) {
        throw Error(error);
      }
    };
    const saveInvoiceOnSharepoint = async function (invoice, uid) {
      invoice.invoiceUid = uid;
      invoice.vendorID = vendor.value.vendorID;
      invoice.vendorName = vendor.value.vendorName;
      invoice.vendorEmail = user.value.email;
      invoice.invoiceNumber = invoice.invoiceNumber.replace(/\s/g, "");
      delete invoice.invoiceStatus;

      try {
        const functions = getFunctions();
        const saveInvoice = httpsCallable(functions, "saveInvoiceToSP");
        await saveInvoice(invoice);
        return "Saved";
      } catch (error) {
        throw Error(error);
      }
    };

    const updateFirebaseInvoiceStatus = async function (uid) {
      let obj = {
        vendorID: vendor.value.vendorID,
        invoiceUid: uid,
        invoiceStatus: "Received",
        reasonForRejection: null,
      };
      const functions = getFunctions();
      const updateStatus = httpsCallable(functions, "updateInvoiceStatus");
      try {
        await updateStatus(obj);
        return "Updated";
      } catch (error) {
        throw Error(error);
      }
    };

    const saveAttachmentOnFirebase = function (file) {
      return new Promise((resolve, reject) => {
        const fileToUpload = Object.values(files.value).find(
          (el) => el.name == file.name
        );
        const metadata = {
          vendorID: vendor.value.vendorID,
          invoiceNumber: formData.value.invoiceNumber,
        };

        fileToUpload.fileName = file.name.replace(/\s/g, "");
        fileToUpload.originalFileName = file.name;
        const storage = getStorage();
        const storageRef = sRef(
          storage,
          `${metadata.vendorID}/${metadata.invoiceNumber}/${fileToUpload.fileName}`
        );
        const uploadTask = uploadBytesResumable(storageRef, fileToUpload, metadata);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            file.UploadPercentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            reject(error);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            const attach = {
              url: url,
              uid: user.value.uid,
              fileName: fileToUpload.fileName,
              originalFileName: fileToUpload.originalFileName,
              addedOn: moment().local().format(),
            };
            resolve(attach);
          }
        );
      });
    };

    return {
      user,
      formData,
      attachments,
      mainForm,
      Currencies,
      Entities,
      fileList,
      files,
      submitInvoice,
    };
  },
};
</script>

<style></style>
