<template>
  <div>
    <h4 class="orange">My Invoices</h4>

    <div id="myInvoicesTable"></div>
    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="invoiceDetails"
      aria-labelledby="invoiceDetailsLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title orange" id="invoiceDetailsLabel">Invoice Details</h5>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>

      <div class="offcanvas-body" v-if="selectedInvoice">
        <div class="row border-bottom lh-lg">
          <div class="col-2">
            <b>Invoice Number</b>
          </div>
          <div class="col-4">
            {{ selectedInvoice.invoiceNumber }}
          </div>
          <div class="col-2 border-start">
            <b>Invoice Date</b>
          </div>
          <div class="col-4">
            {{ dateFormatter(selectedInvoice.invoiceDate) }}
          </div>
        </div>
        <div class="row border-bottom lh-lg">
          <div class="col-2">
            <b>Invoice Amount</b>
          </div>
          <div class="col-4">
            {{ selectedInvoice.invoiceAmount }}
          </div>
          <div class="col-2 border-start">
            <b>Invoice Currency</b>
          </div>
          <div class="col-4">
            {{ selectedInvoice.invoiceCurrency }}
          </div>
        </div>

        <div class="row border-bottom lh-lg">
          <div class="col-2">
            <b>Reference Type</b>
          </div>
          <div class="col-4">
            {{ selectedInvoice.referenceType }}
          </div>
          <div class="col-2 border-start">
            <b>Reference Number</b>
          </div>
          <div class="col-4">
            {{ selectedInvoice.referenceNumber }}
          </div>
        </div>
        <div class="row border-bottom lh-lg">
          <div class="col-2">
            <b>Entity Name</b>
          </div>
          <div class="col-4">
            {{ selectedInvoice.entityName }}
          </div>
          <div class="col-2 border-start">
            <b>Invoice Status</b>
          </div>
          <div class="col-4">
            {{ selectedInvoice.invoiceStatus }}
          </div>
        </div>

        <div
          class="row border-bottom lh-lg"
          v-if="
            selectedInvoice.reasonForRejection &&
            selectedInvoice.reasonForRejection != 'null'
          "
        >
          <div class="col-2">
            <b>Reason For Rejection</b>
          </div>
          <div class="col-10">
            {{ selectedInvoice.reasonForRejection }}
          </div>
        </div>
        <div class="row mt-3 border-bottom">
          <div class="col-2">
            <b>Attachments</b>
          </div>
          <div class="col-10">
            <ul>
              <li v-for="attach in selectedInvoice.attachments" :key="attach.fileName">
                <span v-if="attach.url != ''">
                  <a target="_blank" :href="attach.url">{{ attach.fileName }}</a>
                </span>
                <span v-else>please fix</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
class Invoice {
  constructor(params) {
    this.uid = params.uid;
    this.attachments = params.attachments;
    this.entityName = params.entityName;
    this.invoiceNumber = params.invoiceNumber;
    this.referenceNumber = params.referenceNumber;
    (this.invoiceAmount = `${params.invoiceAmount} ${params.invoiceCurrency}`),
      (this.invoiceDate = moment(params.invoiceDate).format("YYYY-MM-DD"));
    this.submittedOn = moment(params.addedOn).format("YYYY-MM-DD");
    this.invoiceStatus = params.invoiceStatus;
    this.reasonForRejection = params.reasonForRejection || null;
  }
}
import { Offcanvas } from "bootstrap";
import { Grid, h } from "gridjs";
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import {
  getFirestore,
  collection,
  doc,
  query,
  onSnapshot,
  orderBy,
  getDoc,
} from "firebase/firestore";
import "gridjs/dist/theme/mermaid.css";
import moment from "moment";
export default {
  name: "myInvoices",

  setup() {
    const store = useStore();
    const vendor = computed(() => store.getters.getCurrentUserVendor);
    const invoices = ref(null);
    const table = ref(null);
    const offCanvas = ref(null);
    const selectedInvoice = ref(null);
    const error = ref(false);

    onMounted(async () => {
      try {
        store.commit("setPreloaderStatus", true);
        const element = document.getElementById("invoiceDetails");
        offCanvas.value = new Offcanvas(element);
        const db = getFirestore();
        const supplierCollectionRef = collection(db, "Suppliers");
        const supplierRef = doc(supplierCollectionRef, vendor.value.vendorID);
        const invoiceRef = collection(supplierRef, "Invoices");
        const q = query(invoiceRef, orderBy("addedOn", "desc"));

        onSnapshot(
          q,
          (querySnapshot) => {
            invoices.value = [];
            querySnapshot.forEach((invoice) => {
              let data = invoice.data();
              data.uid = invoice.id;
              invoices.value.push(new Invoice(data));
            });
            if (table.value) {
              document.getElementById("myInvoicesTable").innerHTML = "";
              table.value.updateConfig({ data: invoices.value }).forceRender();
            } else {
              displayTable(invoiceRef);
            }
          },
          (err) => {
            error.value = err;
            throw Error(err);
          }
        );
      } catch (error) {
        throw Error(error);
      }

      store.commit("setPreloaderStatus", false);
    });

    watch(
      () => error.value,
      (err) => {
        throw Error(err);
      }
    );

    const displayTable = function (invoiceRef) {
      table.value = new Grid({
        columns: [
          { name: "uid", hidden: true },
          "Invoice Number",
          "Invoice Date",
          "Reference Number",
          "Invoice Amount",
          "Submitted On",
          "Invoice Status",
          {
            name: "View Details",
            formatter: (cell, row) => {
              return h(
                "button",
                {
                  className: "btn btn-sm btn-outline-primary text-center",
                  onClick: async () => {
                    store.commit("setPreloaderStatus", true);
                    selectedInvoice.value = null;
                    const uid = row.cells[0].data;
                    const invoice = doc(invoiceRef, uid);
                    const docSnap = await getDoc(invoice);
                    selectedInvoice.value = docSnap.data();
                    offCanvas.value.show();
                    store.commit("setPreloaderStatus", false);
                  },
                },
                "View"
              );
            },
          },
        ],
        data: invoices.value,
        sort: true,
        pagination: true,
        search: true,
      }).render(document.getElementById("myInvoicesTable"));
    };

    const dateFormatter = function (date, withTime) {
      if (withTime) {
        return moment(date).format("YYYY-MM-DD HH:mm");
      } else {
        return moment(date).format("YYYY-MM-DD");
      }
    };

    return { vendor, invoices, selectedInvoice, dateFormatter };
  },
};
</script>

<style>
#invoiceDetails {
  width: 80%;
}
</style>
